<template>
    <ModuleWrapper :pageTitle="pageTitle">
        <template v-if="!isMobile" v-slot:h_left>
            <PageFilter 
                :model="pageModel"
                :key="pageName"
                size="large"
                :page_name="pageName" />
        </template>
        <template v-if="!isMobile" v-slot:h_right>
            <SettingsButton
                :pageName="pageName"
                class="ml-2" />
        </template>
        <component :is="listComponent" />
        <div 
            v-if="isMobile"
            class="float_add">
            <div class="filter_slot">
                <PageFilter 
                    :model="pageModel"
                    :key="pageName"
                    size="large"
                    :page_name="pageName" />
            </div>
        </div>
    </ModuleWrapper>
</template>

<script>
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'

import SettingsButton from '@/components/TableWidgets/SettingsButton'
import PageFilter from '@/components/PageFilter'
export default {
    components: {
        ModuleWrapper,    
        SettingsButton,
        PageFilter
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        listComponent() {
            if(this.isMobile) {
                return () => import('./components/List.vue')
            } else {
                return () => import('./components/Table.vue')
            }
        }
    },
    data() {
        return {
            pageModel: 'catalogs.ContractorProfileRequestModel',
            pageName: 'catalogs.ContractorProfileRequestModel',
            tableType: 'moderation'
        }
    }
}
</script>